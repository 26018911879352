import React from 'react'
import Markdown from 'react-markdown'

import Layout from "../../layout"
import SEO from "../../seo";
import styles from './single.module.css'
import BreadCrumb from '../../breadcrumb'


const Service = ({data}) => {
	let d = data.strapiServices;
	return(
		<Layout>
    	<SEO title={`Service | ${d.tiltle}`}/>
    	<BreadCrumb title={d.tiltle}/>
	    <div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.headerWrap}>
						<span>{d.slogan}</span>
					</div>
					<img src={d.service_image.localFile.publicURL}/>
				</div>
				<div className={styles.content}>
					<Markdown>{d.content}</Markdown>
				</div>
			</div>
  	</Layout>
)}

export default Service

export const query = graphql`
  query Post($slug: String!) {
  	strapiServices(slug: { eq: $slug }) {
	    service_image {
	      localFile {
	        publicURL
	      }
	    }
	    slug
	    slogan
	    tiltle
	    content
	  }
  }
` 