import React from 'react'
import {graphql,useStaticQuery,Link} from 'gatsby';
import styles from './style.module.css'

const Services = () =>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiServices {
		    edges {
		      node {
		      	slug
  	        tiltle
  	        excerpt
  	        feature_image {
  	          localFile {
  	            publicURL
  	          }
  	        }
		      }
		    }
		  }
		}
	`)
	let services = query.allStrapiServices.edges;
	
	return(
		<div className={styles.wrapper}>
			<h1>Services</h1>
			<div className={styles.services}>
				{
					services.map(({node},index)=>{
						return(
							<div className={styles.item} key={index}>
								<h4>{node.tiltle}</h4>
								<img 
									className={styles.thumb} 
									src={node.feature_image.localFile.publicURL} 
									alt={node.title}
								/>
								<div className={styles.shortDesc}>
									<p>{node.excerpt.substring(0,85)}...</p>
									<Link to={`/service/${node.slug}`}> read more → </Link>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default Services;