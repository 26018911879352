import React,{useState} from 'react';
import {Link} from 'gatsby';
import Drawer from 'react-drag-drawer'

import ContactForm from '../../contact'

import styles from './style.module.css';
import PhoneIcon from '../../../images/icons/phone-green.svg'
import CloseIcon from '../../../images/icons/close-menu.svg'

const DeskMobileMenu = (props) =>{
	const [state, setState] = useState({
		open: false
	})
	const handleMenuClose = () =>{
		props.handleClose();
	}
	const toggle = (e) => {
		e.preventDefault();
		const {open} = state
		setState({...state,open:!open})
	}
	const {menus} = props;
	return(
		<div className={styles.wrapper}>
			<div className={styles.info}>
				<div className={`${styles.contactInfo}`}>
					<img src={PhoneIcon} alt={`LSBA - Phone`}/>
					<p>{props.contact.phone_no}</p>
				</div>
				<div className={styles.menuClose}>
					<img onClick={handleMenuClose} src={CloseIcon} alt={`LSBA - close`}/>
				</div>
			</div>
			<div className={styles.mobileMenus}>
				<ul>
					{
						menus.map((menu,index)=>{
							return(
								<li className={menu.hasChildren ? styles.hasChildren : ''} key={index}>
									<Link to={menu.path} className={menu.hasChildren ? styles.children : ''}>{menu.name}</Link>
									{
										menu.hasChildren && (
											<ul>
												{
													menu.childrens.map((item,i)=>{
														return(<li key={i}><Link to={item.path} activeClassName={styles.isActive}>{item.name}</Link></li>)
													})
												}
											</ul>
										)
									}
								</li>
							)
						})
					}
				</ul>
				<div className={styles.contactWrap}>
					<a className={styles.contactUs} href="#" onClick={(e) =>toggle(e)}>Contact Us</a>
				</div>
			</div>
			<Drawer
	      open={state.open}
	      onRequestClose={toggle}
	      modalElementClass="modal-popup"
	      allowClose={false}
	    >
	    	<ContactForm handleClose={toggle} closeShow={true}/>
	    </Drawer>
		</div>
	)
}

export default DeskMobileMenu;