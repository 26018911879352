import React from 'react'
import {graphql,useStaticQuery,Link} from 'gatsby';

import styles from './style.module.css'
import Thumb from '../../../images/feature_image.svg'

const Services = () =>{
	const query = useStaticQuery(graphql`
		query {
			strapiHomeServiceInfo {
		    title
		    description
		  }
		}
	`)
	const data = query.strapiHomeServiceInfo;
	return(
		<div className={styles.wrapper}>
			<div className={styles.infoWrap}>
				<div className={`${styles.title} ${styles.info}`}>
					<h1>
						{data.title}
					</h1>
					<Link to="/about-us" className="btn">Read More</Link>
				</div>
				<div className={`${styles.desc} ${styles.info}`} dangerouslySetInnerHTML={{__html:data.description}}>
				</div>
			</div>
		</div>
	)
}

export default Services;