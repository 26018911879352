import React from 'react'
import { graphql, useStaticQuery} from "gatsby"
import styles from './style.module.css';

const Expertise = () => {
	const query = useStaticQuery(graphql`
		query {
	    strapiExpertiseitem {
        item {
          title
          feature_image {
            localFile {
              publicURL
            }
          }
        }
      }
		}
	`)
	const expertises = query.strapiExpertiseitem.item;
	return(
		<div className={styles.expertiseWrapper}>
			{
				expertises.map((item,index)=>{
					return(
						<div className={styles.item} key={index}>
							<img src={item.feature_image && item.feature_image ? item.feature_image.localFile.publicURL:''} alt={item.title}/>
							<div className={styles.excerpt}>
								<span>{item.title}</span>
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default Expertise