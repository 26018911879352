import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import PhoneWhite from '../../../images/icons/phone-white.svg'
import styles from './style.module.css';

const Info = () => {
	const query = useStaticQuery(graphql`
		query {
			strapiHomeInfo {
		    free_text
		    license
		  }
	  	strapiContactInfo {
	      phone_no
	    }
		}
	`)
	const data = query.strapiHomeInfo;
	const contact = query.strapiContactInfo;
	return(
		<>
			<div className={styles.license}>
				<p>License: {data.license}</p>
			</div>
			<div className={styles.bottomWrapper}>
				<div className={styles.info} dangerouslySetInnerHTML={{__html: data.free_text}}/>
				<div className={styles.contactUs}>
					<a href={`tel:${contact.phone_no}`}><img src={PhoneWhite} alt={`LSBA - Telephone`}/> <span>Call Now</span></a>
				</div>
			</div>
		</>
	)
}

export default Info