import React from 'react'
import styles from './style.module.css';
import { graphql, useStaticQuery,Link} from "gatsby"
import Services from '../service/services'

const ServiceList = () =>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiServices {
		    edges {
		      node {
		      	tiltle
		      	slogan
		        list {
		          name
		          id
		        }
		      }
		    }
		  }
		}
	`)
	let list = [];
	let services = query.allStrapiServices.edges;
	services.forEach(({node})=>{
		if((node.list && node.list.length) && list.length <=3 ){
			list.push(node);
		}
	})
	return(
		<div className={styles.wrapper}>
			<Services/>
			<div className={styles.container}>
				<h1>What we do ?</h1>
				<div className={styles.itemsWrapper}>
					{
						list.map((item,index)=>{
							return(
								<div className={styles.item} key={index}>
									<div className={styles.head}>
										<h4>{item.tiltle}</h4>
										<span>{item.slogan}</span>
										<div className={styles.lists}>
											<ul>
												{
													item.list.map((it,i)=>{
														return (<li key={i}>{it.name}</li>)
													})
												}
											</ul>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className={styles.readMore}>
				<Link to="/services" className="btn">Read More</Link>
			</div>
		</div>
	)
}

export default ServiceList;