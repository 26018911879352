import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadCrumb from '../components/breadcrumb/index';
import ServiceArea from '../components/service-areas/index'

const ServiceAreaPage = () => (
  <Layout>
    <SEO title="Service Areas" />
    <BreadCrumb title={`Service Areas`}/>
    <ServiceArea/>
  </Layout>
)

export default ServiceAreaPage
