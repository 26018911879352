import React,{useState,useEffect,useRef} from 'react'
import Drawer from 'react-drag-drawer'
import { graphql, useStaticQuery, Link} from "gatsby"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import window from 'global'

import styles from './header.module.css'
import Phone from '../../images/icons/phone.svg'
import PhoneWhite from '../../images/icons/phone-white.svg'
import Home from '../home/index'
import ContactForm from '../contact/index'
import MainMenu from './main-menu'
import HomeInfo from '../home/info'
import Expertise from '../home/expertise'
import DesMobileMenu from './mobile-menu'
import MobileMenu from '../../images/mobile-menu.svg'
import MLogo from '../../images/m-logo.svg'
import ScrollLogo from '../../images/scroll-logo.svg'
import menus from './nav'

const Header = ({isHome,location}) => {
	const [state, setState] = useState({
		open: false,
		isMobileMenu: false,
		device: 'desktop',
		isScroll: false
	})
	const scroll = window.scrollY 
	useEffect(()=>{
		const ua = navigator.userAgent;
		let device;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      device = "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      device = "mobile";
    }
    else{
    	device = "desktop";
    }
    setState({...state,device:device});
	},[])

	useScrollPosition(({ prevPos, currPos }) => {
		const scrollY = window.scrollY 
	  if(scrollY > 250 && scroll !== 0){
	  	setState({...state, isScroll: true})
	  }
	  else{
	  	setState({...state, isScroll: false})
	  }
	})
	const query = useStaticQuery(graphql`
		query {
			strapiContactInfo {
		    phone_no
		  }
			allStrapiServices {
		    edges {
		      node {
		      	slug
  	        tiltle
  	      }
		    }
		  }
		  strapiHeaderInfo {
	      logo {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        mobile_logo {
          localFile {
            publicURL
          }
        }
        mobile_scroll_logo {
          localFile {
            publicURL
          }
        }
	    }
		}
	`)
	const data = query.strapiContactInfo;
	const header = query.strapiHeaderInfo;
	const sMenus = query.allStrapiServices.edges;
	sMenus.forEach((item)=>{
		item.name = item.node.tiltle;
		item.path = `/service/${item.node.slug}`
	})
	menus.forEach((item)=>{
		if(item.name === 'Services' && item.hasChildren){
			item.childrens = sMenus;
		}
	})
	
	const toggle = (e) => {
		e.preventDefault();
		const {open} = state
		setState({...state,open:!open})
	}

	const handleMobileMenu = () =>{
		setState({...state, isMobileMenu: true})
	}
	const handleMobileMenuClose = () =>{
		setState({...state, isMobileMenu: false})
	}
	
	const {device, isScroll} = state
	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.topNav}>
						<ul>
							<li>
								<img src={Phone} alt={`LSBA - Telephone`}/>
								<a href={`tel:${data.phone_no}`}>{data.phone_no}</a>
							</li>
							<li className={styles.logo}>
								<Link to="/"><img className={styles.deskTopLogo} src={header.logo.localFile.childImageSharp.fluid.src} alt={`LSBA - Logo`}/></Link>
								<Link to="/"><img className={styles.deskMobileLogo} src={header.mobile_logo.localFile ? header.mobile_logo.localFile.publicURL : MLogo} alt={`LSBA - Logo`}/></Link>
							</li>
							<li className={styles.mobileMenu}>
								<img onClick={handleMobileMenu} src={MobileMenu} alt="mobile-menu"/>
							</li>
							<li>
								<a href="#" onClick={(e) =>toggle(e)}>Contact us</a>
							</li>
						</ul>
						<div className={styles.textSlogan}>
							<h1>1st Metropolitan</h1>
							<span>Locksmith</span>
						</div>
					</div>
					{
						(state.device === 'mobile' && state.isScroll) && (
							<div className={styles.mobileScrollWrap}>
								<div className={styles.scrollLogoWrap}>
									<Link to="/"><img src={header.mobile_scroll_logo.localFile ? header.mobile_scroll_logo.localFile.publicURL : ScrollLogo} alt={`LSBA - scroll Logo`}/></Link>
								</div>
								<div className={styles.mobileScrollContact}>
									<img src={PhoneWhite} alt={`LSBA - phone-white`}/>
									<a href={`tel:${data.phone_no}`}>Call Now </a>
								</div>
							</div>
						)
					}
					<MainMenu menus={menus}/>
					{
						state.isMobileMenu && (
							<DesMobileMenu contact={data} menus={menus} handleClose={handleMobileMenuClose}/>
						)
					}
					{
						isHome && (<>
							<HomeInfo/>
							<Expertise/>
						</>)
					}
				</div>
			</div>
			<Drawer
	      open={state.open}
	      onRequestClose={toggle}
	      modalElementClass="modal-popup"
	      allowClose={false}
	    >
	    	<ContactForm handleClose={toggle} closeShow={true}/>
	    </Drawer>
		</>
	)
}

export default Header