import React from 'react'

import Contact from '../contact'
import Service from './service'
import ServiceList from './service-list'

const Home = () => {
	return(
		<>
			<Service/>
			<ServiceList/>
			<Contact isHome={true}/>
		</>
	)
}

export default Home