import React,{useState} from 'react'
import {gatsby, useStaticQuery} from 'gatsby';
import styles from './style.module.css'
import ContactForm from '../contact/email-form';

const ServiceArea = () => {
	const [pointerEvent, setPointerEvent] = useState('none');
	const handleZoom = () =>{
		setPointerEvent('all')
	}

	const handleMouseLeave = () => {
		setPointerEvent('none');
	}

	const query = useStaticQuery(graphql`
		query {
			strapiServiceAreaInfo {
		    title
		    description
		    service_areas {
		      name
		    }
		    address
		  }
		}
	`)
	const data = query.strapiServiceAreaInfo;
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.services}>
					<h3>{data.title}</h3>
					<p>{data.description}</p>
					<div className={styles.serviceList}>
						<ul>
							{
								data.service_areas.map((item,index)=>(
									<li key={index}>{item.name}</li>
								))
							}
						</ul>
					</div>
				</div>
				<div className={styles.contact}>
					<>
						<h1>Contact Us</h1>
						<p>Feel free to</p>
						<p>contact us anytime</p>
					</>
					<ContactForm/>
				</div>
			</div>
			<div className={styles.maps} onClick={handleZoom} onMouseLeave={handleMouseLeave}>
				<iframe src={data.address}
					width="100%"
     			height="600"
     			frameBorder="0"
     			style={{border:0, pointerEvents:pointerEvent, marginTop: '-50px',height:'600px', position: 'relative'}}></iframe>
			</div>
		</div>
	)
}
export default ServiceArea;