import React,{useState, useEffect} from 'react'
import {gatsby,useStaticQuery,Link} from 'gatsby';
import styles from './style.module.css';

const ServicePage = () =>{
	const [state, setState] = useState({
		device: 'desktop'
	})
	useEffect(()=>{
		const ua = navigator.userAgent;
		let device;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      device = "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      device = "mobile";
    }
    else{
    	device = "desktop";
    }
    setState({...state,device:device});
	},[])
	const query = useStaticQuery(graphql`
		query {
			allStrapiServices {
		    edges {
		      node {
		      	slug
  	        tiltle
  	        excerpt
  	        slogan
  	        service_image {
  	          localFile {
  	            publicURL
  	          }
  	        }
		      }
		    }
		  }
		  strapiServiceInfo {
	      title
	      description
	    }
		}
	`)
	let services = query.allStrapiServices.edges;
	services.forEach(({node}, index)=>{
		if(index % 2 === 0)
			node.orientation = 'left'
		else
			node.orientation = 'right'
	})
	const service = query.strapiServiceInfo;
	return (
		<div className={styles.wrapper}>
			<div className={styles.textWrapper}>
				<p className={styles.description}>{service.description}</p>
				<h5>{service.title}</h5>
			</div>
			<div className={styles.services}>
			{
				services.map(({node}, index)=>{
					return (
						<div className={styles.item} key={index}>
							{
								(node.orientation === 'left' || state.device === 'mobile') && (
									<img className={styles.itemList} src={node.service_image.localFile.publicURL} alt={`LSBA - ${node.tiltle}`}/>
								)
							}
							<div className={`${styles.textItem} ${styles.itemList} ${styles[node.orientation]}`}>
								<h3>{node.tiltle}</h3>
								<span>{node.slogan}</span>
								<p>{node.excerpt}</p>
								<Link to={`/service/${node.slug}`}>read more →</Link>
							</div>
							{ 
								(node.orientation === 'right' && state.device === 'desktop') && (
									<img src={node.service_image.localFile.publicURL} alt={`LSBA - ${node.tiltle}`}/>
								)
							}
						</div>
					)
				})
			}				
			</div>
		</div>
	)
}

export default ServicePage;