import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import styles from './contact.module.css'
import Phone from '../../images/icons/phone-sm.svg'
import Envelop from '../../images/icons/envelop.svg'
import Close from '../../images/icons/close.svg'
import Location from '../../images/icons/location.svg'
import EmailForm from './email-form'

const Contact = ({handleClose, closeShow=false, isHome}) => {
	const query = useStaticQuery(graphql`
		query {
			strapiContactInfo {
		    phone_no
		    email
		    address
		  }
		}
	`)
	const data = query.strapiContactInfo;
	return(
		<>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					{
						closeShow &&
						<a href="#" className={styles.closePopup} onClick={handleClose}>
							<img src={Close} alt={`LSBA - close`}/>
						</a>
					}
					<div className={`${styles.contactWrapper} ${closeShow ? styles.showPopUp : styles.notShowPopUp}`}>
						<div className={styles.contactWrap}>
						{
							!isHome && (
								<h1>Contact us</h1>
							)
						}
							<div className={styles.info}>
								{
									isHome && (
									<div className={styles.infoWrap}>
										<h1>Contact us</h1>
										<p>Feel free to</p>
										<p>contact us anytime</p>
									</div>
									)
								}
								{
									!isHome && (
										<ul>
											<li>
												<img src={Phone} alt={`LSBA - Telephone`}/>
												<div>
													<span>Call Now:</span> 
													<p>{data.phone_no}</p>
												</div>
											</li>
											<li className={styles.contactEmail}>
												<img src={Envelop} alt={`LSBA - email`}/>
												<p>{data.email}</p>
											</li>
											{
												data.address && (
													<li>
														<img src={Location} alt={`LSBA - address`}/>
														<div dangerouslySetInnerHTML={{__html: data.address}}/>
													</li>
												)
											}
										</ul>
									)
								}
							</div>
						</div>
						<div className={`${styles.contactWrap} ${styles.formWrapper}`}>
							{
								!isHome && (
								<>
									<p>Feel free to</p>
									<p>contact us anytime</p>
								</>
							)}
							<EmailForm styles={styles} isHome={isHome}/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact