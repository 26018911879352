import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
const style={
  textAlign: 'center',
  margin: '80px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0.1em'
}

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <p style={style}>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
