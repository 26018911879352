import React from 'react'
import emailjs from 'emailjs-com';
import Toast from 'light-toast';
import styles from './form.module.css'

class EmailForm extends React.Component {
	state ={
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    message: '',
    errors: {
			first_name: null,
			phone: null,
			email: null,
			message: null
		},
  };

	handeleSubmit = (e) => {
		e.preventDefault();
		for (let prop in this.state) {
			this.validation(prop, this.state[prop]);
		}
		for (let prop in this.state.errors) {
			if (this.state.errors[prop] !== null) {
				return 0;
			}
		}
		this.sendMail();
	}
	handleChange = (e) =>{
		let name = e.target.name;
		let value = e.target.value
		this.setState({
			[name]: value
		})
		this.validation(name, value)
	}

	wordSepearate(word) {
		if(!word)
			return 0
		word = word.split('_');
		let d='';
		for(let prop in word){
			d+= `${word[prop]} `;
		}
		return d[0].toUpperCase() + d.slice(1)
	}

	validation(name, value){
		let {errors} = this.state;
		if (Object.keys(this.state.errors).indexOf(name) > -1) {
			errors[name] = value.length < 1 ? `${this.wordSepearate(name)} field is required!` : null;
			if(name === 'email') {
				errors['email'] = value.length < 1 ? 'Email field is required!': this.isEmail(value) === false ? `Please enter a valid email!` : null;
			}else if(name === 'phone'){
				errors['phone'] = value.length < 1 ? 'Phone field is required!': this.isDigit(value) === false ? `Please enter a valid phone number!` : null;
			}
			this.setState({ errors, [name]: value }, () => {
			})
		}
	}
	isEmail(email){
	  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
	  return regex.test(email);
	}	
	isDigit(phone){
	  let regex = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
	  return regex.test(phone);
	}	
	async sendMail(){
		let templateID = process.env.EMAIL_TEMPLATE_ID;
		let serviceID = process.env.EMAIL_SERVICE_ID;
		let userID = process.env.EMAIL_USER_ID;
		const params = {
			first_name : this.state.first_name,
			last_name : this.state.last_name,
			inquiry_number: Date.now(),
			email: this.state.email,
			phone: this.state.phone,
			message: this.state.message,
			recieved_mail: process.env.RECIEVER_EMAIL_ID
		}
		try {
			Toast.loading('Sending...');
			await emailjs.send(serviceID, templateID, params, userID);
			Toast.hide();
			Toast.success('Email was sent successfully.', 5000);
			this.setState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        message: '',
		  })
		}
		catch(error){
			console.log(error)
		}
	}

	render() {
		const {isHome} = this.props
		return(
			<div className={styles.form}>
				<form onSubmit={this.handeleSubmit}>
					<div className={styles.formGroupWrapper}>
						<div className={styles.formGroup}>
							<input 
								type="text" 
								className={`${styles.formControl} ${this.state.errors['first_name'] ? styles.formError : ''}`} 
								placeholder="First Name" 
								name="first_name"
								value={this.state.first_name}
								required="required"
								onChange={(e)=>this.handleChange(e)}
							/>
							<span className={this.state.errors['first_name'] ? styles.vError: ''}>
								{this.state.errors['first_name']}
							</span>
						</div>
						<div className={styles.formGroup}>
							<input 
								type="text" 
								className={styles.formControl} 
								placeholder="Last Name" 
								name="last_name"
								value={this.state.last_name}
								onChange={(e)=>this.handleChange(e)}
							/>
						</div>
					</div>
					<div className={styles.formGroupWrapper}>
						<div className={styles.formGroup}>
							<input 
								type="text" 
								className={`${styles.formControl} ${this.state.errors['phone'] ? styles.formError : ''}`} 
								placeholder="8 (652) 12 234 234" 
								name="phone"
								value={this.state.phone}
								required="required"
								onChange={(e)=>this.handleChange(e)}
							/>
							<span className={this.state.errors['phone'] ? styles.vError: ''}>
								{this.state.errors['phone']}
							</span>
						</div>
					</div>
					<div className={styles.formGroupWrapper}>
						<div className={styles.formGroup}>
							<input 
								type="email" 
								className={`${styles.formControl} ${this.state.errors['email'] ? styles.formError : ''}`} 
								placeholder="Email" 
								name="email"
								value={this.state.email}
								required="required"
								onChange={(e)=>this.handleChange(e)}
							/>
							<span className={this.state.errors['email'] ? styles.vError: ''}>
								{this.state.errors['email']}
							</span>
						</div>
					</div>
					<div className={styles.formGroupWrapper}>
						<div className={styles.formGroup}>
							<textarea  
								onChange={(e)=>this.handleChange(e)}
								className={`${styles.formControl} ${this.state.errors['message'] ? styles.formError : ''}`}
								rows="4" 
								placeholder="Message" 
								name="message"
								value={this.state.message}
								required="required"
							/>
							<span className={this.state.errors['message'] ? styles.vError: ''}>
								{this.state.errors['message']}
							</span>
						</div>
					</div>
					<button type="submit" className={styles.contactBtn}>Send</button>
				</form>
			</div>
		)
	}
}

export default EmailForm