import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './footer.module.css'
import Phone from '../../images/icons/phone-sm.svg'
import Envelop from '../../images/icons/envelop.svg'
import Location from '../../images/icons/location.svg'

const Footer = () => {
	const query = useStaticQuery(graphql`
		query {
			strapiContactInfo {
		    phone_no
		    email
		    address
		  }
		  strapiFooterInfo {
	      logo {
	      	localFile {
		        childImageSharp {
		          fluid {
		            src
		          }
		        }
		      }
	      }
	      copy_right
	    }
		}
	`)
	const data = query.strapiContactInfo;
	const footer = query.strapiFooterInfo;
	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.topFooter}>
						<div className={styles.logo}>
							<Link to="/">
								<img src={footer.logo.localFile.childImageSharp.fluid.src} alt={`LSBA - logo`}/>
							</Link>
						</div>
						<ul>
							<li><img src={Phone} alt={`LSBA - Telephone`}/>Call Now: {data.phone_no}</li>
							<li><img src={Envelop} alt={`LSBA - email`}/>{data.email}</li>
							{
								data.address && (
									<li><img src={Location} alt={`LSBA - address`}/>
										<div dangerouslySetInnerHTML={{__html: data.address}} style={{display: 'inline-flex'}}/>
									</li>
								)
							}
						</ul>
					</div>
					<div className={styles.footerMenus}>
						<ul>
							<li><Link to="/about-us">About Us</Link></li>
							<li><Link to="/services">Services</Link></li>
							<li><Link to="/service-areas">Service Areas</Link></li>
							<li><Link to="/contact-us">Contact Us</Link></li>
						</ul>
					</div>
				</div>
			</div>
			<div className={styles.bootomFooterWrapper}>
				<p>{footer.copy_right}</p>
			</div>
		</>
	)
}

export default Footer