import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Services from '../commons/services/index'
import styles from './style.module.css'
import Bullet from '../../images/icons/bullet.svg'

const AboutUs = () =>{
	const query = useStaticQuery(graphql`
		query {
			strapiAboutInfo {
		    title
		    page_title
		    owner_image {
		      localFile {
		        publicURL
		        childImageSharp {
		          fluid {
		            src
		          }
		        }
		      }
		    }
		    why_choose_us {
		      name
		    }
		    description
		    bg_image {
		      localFile {
		        publicURL
		      }
		    }
		  }
		  strapiExpertiseitem {
		    item {
		      title
		      feature_image {
		        localFile {
		          publicURL
		        }
		      }
		    }
		  }
		}
	`)
	const about = query.strapiAboutInfo;
	const {why_choose_us} = about;
	const items = query.strapiExpertiseitem;
	const{item} = items
	return(
		<div className={styles.wrapper}>
			<div className={styles.upperWrapper}>
				<div className={`${styles.upperWrapperText} ${styles.upperWrap}`}>
					<h1>{about.title}</h1>
					<div dangerouslySetInnerHTML={{__html:about.description}}/>
				</div>
				<div className={`${styles.upperWrapperImg} ${styles.upperWrap}`}>
					<img src={about.owner_image.localFile.childImageSharp.fluid.src} alt={`LSBA - owner image`}/>
				</div>
			</div>
			<div className={styles.expertiseWrapper}>
				<div className={styles.bgWrap}>
					<img src={about.bg_image.localFile.publicURL} alt={`LSBA - BG Image`}/>
				</div>
				<div className={styles.expertise}>
					{
						item.map((item,index)=>(
							<div className={styles.item} key={index}>
								<img src={item.feature_image.localFile.publicURL} alt={`LSBA - ${item.title}`}/>
								<h5>{item.title}</h5>
							</div>
						))
					}
				</div>
			</div>
			<div className={styles.chooseWrapper}>
				<h1>Why Choose Us?</h1>
				<div className={styles.chooseItems}>
					<ul>
						{
							why_choose_us.map((item,index)=>(
								<li key={index}>
									<img src={Bullet} alt={`LSBA-${item.name}`}/>
									<h5>{item.name}</h5>
								</li>
							))
						}
					</ul>
				</div>
			</div>
			<Services title={`Services`}/>
		</div>
	)
}

export default AboutUs;