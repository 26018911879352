import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadCrumb from '../components/breadcrumb/index';
import Faq from '../components/faq/index'

const FaqPage = () => (
  <Layout>
    <SEO title="Faq" />
    <BreadCrumb title={`Faq`}/>
    <Faq/>
  </Layout>
)

export default FaqPage
