import React from 'react'
import { Link } from "gatsby"
import styles from './mainmenu.module.css'
import ArrowUp from '../../images/icons/arrow-up.svg'
import ArrowDown from '../../images/icons/arrow-down.svg'

const MainMenu = (props) =>{
	const {menus} = props
	return(
		<div className={styles.mainNav}>
			<ul className={styles.mainNavUl}>
			{
				menus.map((menu,index)=>{
					return(
						<li key={index} className={menu.hasChildren ? styles.menuChildrens : ''}>
							<Link to={menu.path} activeClassName={styles.isActive}>
								{menu.name}
								{
									menu.hasChildren && (
										<>
											<img className={styles.arrowUp} src={ArrowUp} alt={`sks - arrow-up`}/>
											<img className={styles.arrowDown} src={ArrowDown} alt={`sks - arrow-down`}/>
										</>
									)
								}
							</Link>
							{
								menu.hasChildren && (
									<ul>
									{
										menu.childrens.map((item,i)=>{
											return(
												<li key={i}>
													<Link to={item.path}>{item.name}</Link>
												</li>
											)
										})
									}
									</ul>
								)
							}
						</li>
					)
				})
			}
			</ul>
		</div>
	)
}

export default MainMenu