const Data = [
{
	name:'About Us',
	path:'/about-us',
	hasChildren:false
},
{
	name:'Services',
	path:'/services',
	hasChildren:true,
	childrens :[{
		name: 'Queens',
		path:'/queens',
	},
	{
		name: 'Manhattan',
		path:'/manhattan'
	},
	{
		name:'Brooklyn',
		path:'/brooklyn'
	}]
},
{
	name:'Service Areas',
	path:'/service-areas',
	hasChildren:false,
	childrens :[{
		name: 'Queens',
		path:'/queens',
	},
	{
		name: 'Manhattan',
		path:'/manhattan'
	},
	{
		name:'Brooklyn',
		path:'/brooklyn'
	}]
},
{
	name:'Faq',
	path:'/faq',
	hasChildren:false
}];

export default Data;