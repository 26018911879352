import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import Home from '../components/home'
// import Contact from '../components/contact/index'

const IndexPage = () => (
  <Layout isHome={true}>
    <SEO title="Home" />
    <Home/>
  </Layout>
)

export default IndexPage
