import React,{useState,useEffect} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styles from './style.module.css'
import ArrowDown from '../../images/icons/arrow-up-1.svg'
import ArrowUp from '../../images/icons/arrow-down-1.svg'

const FaqPage = () => {
	const [state, setState] = useState(-1);
	const query = useStaticQuery(graphql`
		query{
			allStrapiFaqs {
		    edges {
		      node {
		        title
		        faqs {
		          description
		          title
		        }
		      }
		    }
		  }
		}
	`)
	const faqs = query.allStrapiFaqs.edges;

	const handleFaq = (e,i, index) =>{
		e.preventDefault();
		setState(state === `${i}-${index}` ? -1 : `${i}-${index}`)
	}
	return(
		<div className={styles.wrapper}>
			{
				faqs.map(({node},index)=>{
					return(
						<div className={styles.container} key={index}>
							<h1>{node.title}</h1>
							<div className={styles.faqWrapper}>
								{
									node.faqs.map((item,i)=>{
										return(
											<div className={`${styles.faqContent} ${state === `${i}-${index}` ? styles.isActiveContent : ''}`} key={i}>
												<div className={`${styles.headerFaq} ${state === `${i}-${index}` ? styles.activeHeaderFaq : ''}`}>
													<a href="#" onClick={(e)=>handleFaq(e,i,index)}>{item.title}</a>
													{
														state === `${i}-${index}` && (
															<img src={ArrowDown} alt={`LSBA - arrow-down`}/>
														)
													}
													{
														state !== `${i}-${index}` && (
															<img src={ArrowUp} alt={`LSBA - arrow-up`}/>
														)
													}
												</div>
												<div className={`${styles.faqDesc} ${state === `${i}-${index}` ? styles.isActiveFaq: styles.notActiveFaq}`} dangerouslySetInnerHTML={{__html:item.description}}/>
											</div>
										)
									})
								}
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default FaqPage