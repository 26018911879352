import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from '../components/about/index'
import BreadCrumb from '../components/breadcrumb/index';

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <BreadCrumb title={`About Us`}/>
    <About/>
  </Layout>
)

export default AboutUsPage
